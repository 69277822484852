import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Form, Button} from "react-bootstrap";
import Section from "../Components/Section";

import jsonImg3 from "../Content/json3.JPG";

class How extends React.Component {

  async sendMsg(e){
    try{
    e.preventDefault();
    const {Cname,Cemail,Cnumber,Cmsg}= e.target.elements;
    console.log(Cmsg.value)
    let details={
        site:"JSONHelper",
        name:Cname.value,
        email:Cemail.value,
        phone:"",
        message:Cmsg.value
    }
    let response = await fetch("https://mdqzrv338i.execute-api.us-east-1.amazonaws.com/default/contactForm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    await response.json();
    alert("Message Sent, appreciate the feedback :)");
  }catch(e){
    alert("Whoops, couldn't send the message. Please try again later.");
  }
}

  render() {
    return (
      <div id="howTo">
            <Container id="foot">
                <h1>How Our Tool Works !</h1>
                <br/>
                <br/>
                <Section
                img={jsonImg3}
                first={true}
                >
                    <br/>
                    <br className="d-none d-lg-flex" />
                    
                    <br className="d-none d-lg-flex" />
                    <h3>Making JSON More Human Readable</h3>
                    <br className="d-none d-lg-flex" />
                    <p>JSON is Great but one of its drawbacks is the limitations of being user friendly for first timers. We hope to address this with our tool.</p>
                    <br className="d-none d-lg-flex" />
                    <p>Simply paste your JSON code in the editor window and have your values color coded. Simply click to collapse/expand your JSON Objects and Arrays.</p>
                  
                </Section>
                
                <Row id="sectRow">
                  <Col md={{span:6,order:1}} sm={{span:12,order:2}}>
                    <br/>
                    <h3>Feedback? Reach out to us!</h3>
                    <br className="d-none d-lg-flex" />
                    <p>We're still making adjustments to making the BEST JSON editor. Go ahead and reach out to us for any suggestions and comments. And YES, We DO NOT Store any JSON data, everything is handled in your browser.</p>
                    
                    <p>Our parent site is <a href="http://uvgrade.com/">UVGrade.com</a> and our mission is to make cool and useful apps like this. Check out our other projects!</p>
                    <h5><a href="mailto:info@uvgrade.com">info@uvgrade.com</a></h5><br/>
                  </Col>
                  <Col md={{span:6,order:2}} sm={{span:12,order:1}}>
                    <Form onSubmit={this.sendMsg}>
                    <Form.Group>
                      <Form.Label>Name:</Form.Label>
                      <Form.Control id="Cname" type="text"/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Email:</Form.Label>
                      <Form.Control id="Cemail" type="email"/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Message:</Form.Label>
                      <Form.Control id="Cmsg" as="textarea" rows={3} required/>
                    </Form.Group>
                    <br/>
                    <Button id="formB" type="submit">Submit</Button>
                  </Form>
                  </Col>
                </Row>
            </Container>
      </div>
    );
  }
}
export default How;

