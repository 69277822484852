import React , { useEffect, useState }from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Form, Row, Col,Button, Collapse} from "react-bootstrap";
import sample from '../Content/sample.json'

//import CodeMirror from '@uiw/react-codemirror';
//import {Controlled as CodeMirror} from 'react-codemirror2'

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyBFaoEaP51QlxlDXG_BplJmVbZatyusRis",
  authDomain: "jsonhelper-65d7a.firebaseapp.com",
  projectId: "jsonhelper-65d7a",
  storageBucket: "jsonhelper-65d7a.appspot.com",
  messagingSenderId: "983475076927",
  appId: "1:983475076927:web:2c069c0a1be06a11f79d8a",
  measurementId: "G-DC4RCGLN4M"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

class Tool extends React.Component {
  constructor(){
    super()
    this.state= {
      code:"",
      code2:"",
      step1:true
    }
    this.copyFclip = this.copyFclip.bind(this)
    this.clrCode = this.clrCode.bind(this)
    this.vwCode = this.vwCode.bind(this)
    this.step2= this.step2.bind(this)
    this.demo= this.demo.bind(this)
  }

  copyFclip(){
    navigator.clipboard.readText()
    .then(text => {
      console.log(text)
      this.setState({code2:text})
    })
    .catch(err => {
      // maybe user didn't grant access to read from clipboard
      console.log('Something went wrong', err);
    });
  }
  clrCode(){
    this.setState({code2:""})
    this.setState({step1:true})
  }
  vwCode(){
    this.setState({step1:true})
  }
  demo(){
    console.log("step 2")
    this.setState({code2:JSON.stringify(sample)})
    var code3=JSON.stringify(sample)
    try{
      JSON.parse(code3)
      this.setState({step1:false})
    }catch (e){
      alert("Code doesn't seem to be JSON format or errors detected, please try again later")
    }
  }
  step2(){
    console.log("step 2")
    var code3=this.state.code2
    try{
      JSON.parse(code3)
      this.setState({step1:false})
    }catch (e){
      alert("Code doesn't seem to be JSON format or errors detected, please try again later")
    }

  }
  render() {

   
    console.log(sample)
    return (
      <div id="home">
            <Container id="foot">
                <h1>JSON Tool</h1>
                <br/>
                {this.state.step1?
                <JInput code2={this.state.code2}
                parent={this}
                copyFclip={this.copyFclip}
                clrCode={this.clrCode}
                demo={this.demo}
                step2={this.step2} />
                :
                <JEdit
                parent={this}
                clrCode={this.clrCode}
                vwCode={this.vwCode}
                />}
                
            </Container>
      </div>
    );
  }
}
export default Tool;




function JInput(props){
  const {copyFclip,clrCode,step2,demo} = props;
  return(
    <>
    <h3>Stage 1: Enter JSON Text to convert</h3><br/>
               


                <Form.Group>
                  <Form.Control id="Cmsg" as="textarea" rows={20} value={props.parent.state.code2}
                  onChange={(e) => props.parent.setState({ code2: e.target.value }) }/>
                  <Row style={{paddingTop:"20px"}}>
                      <Col md="auto">
                        <Row>
                          <Col><Button onClick = { copyFclip }>Paste from Clipboard</Button></Col>
                          <Col><Button variant="danger" onClick = { clrCode }>Clear</Button></Col>
                        </Row>
                      </Col>
                     
                      <Col style={{textAlign: "right"}}>
                        <Row>
                          <Col><Button variant="info" onClick={ demo }>JSON Demo</Button></Col>
                          <Col><Button onClick={ step2 }>Step 2: Prettify</Button></Col>
                        </Row>
                      </Col>
                  </Row>
                </Form.Group>
                
    </>
  );
                
}

function JEdit(props){
  var code=JSON.parse(props.parent.state.code2)
  const {clrCode,vwCode} = props;
  return(
    <>
    <h3>Stage 2: Explore JSON File</h3>
    <br/>
    <Row>
      <Col><Button variant="danger" onClick={clrCode}>Start Over</Button></Col>
      <Col style={{textAlign: "right"}}><Button onClick={vwCode}>View Code</Button></Col>
    </Row>
    <ObjCard obj={code}/>
    
    </>
  );
}

function ObjCard(obj){
  const [open, setOpen] = useState(true);
  const size=Object.keys(obj.obj).length
  return(
    <div id="objCard">
      <Row>
        <Col md="auto"><Button
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
      >{open? "+":"-"} Object</Button></Col>
      <Col>
      {
            size===1?
              <div>1 Item</div>:
              <div>{size} Items</div>
          }
          </Col>
      </Row>
      
      <Collapse in={open}>
        <div id="example-collapse-text">
        {
          Object.keys(obj.obj).map(k=> {
            return <div id="objKey">
                  <Row>
                    <Col md="auto"><div id="objKeyT">{k} </div></Col><Col md="auto"><div id="objKeyT">:</div></Col>
                    <Col md="auto">
                      {typeof obj.obj[k]==='string'?
                        <div id="objValS">"{obj.obj[k]}"</div>:
                        typeof obj.obj[k]==='number'?
                          <div id="objValN">{obj.obj[k]}</div>:
                          typeof obj.obj[k]==='boolean'?
                            <div id="objValB">{obj.obj[k].toString()}</div>:
                            Array.isArray(obj.obj[k])?
                              <ArrCard obj={obj.obj[k]}/>:
                              typeof obj.obj[k] === 'object' && obj.obj[k] !== null?
                                <ObjCard obj={obj.obj[k]}/>:
                                <div id="objValB">Null</div>
                      }
                        
                    </Col></Row>
              </div>
          })
        }
        </div>
        
      </Collapse>
    </div>
    
  );
}

function ArrCard(obj){
  
  const [open, setOpen] = useState(true);
  const size=Object.keys(obj.obj).length
  return(
    <div id="arrCard">
      <Row>
        <Col md="auto"><Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
        >{open? "+":"-"} Array</Button></Col>
        <Col>
          {
            size===1?
              <div>1 Item</div>:
              <div>{size} Items</div>
          }
          </Col>
      </Row>
      <Collapse in={open}>
        <div id="example-collapse-text">
        {
          Object.keys(obj.obj).map(k=> {
            return <div>
                    {Array.isArray(obj.obj[k])?
                      <ArrCard obj={obj.obj[k]}/>:
                      typeof obj.obj[k] === 'object' && obj.obj[k] !== null?
                        <ObjCard obj={obj.obj[k]}/>:
                        <div id="objKey">
                          {typeof obj.obj[k]==='string'?
                            <div id="objValS">"{obj.obj[k]}"</div>:
                            typeof obj.obj[k]==='number'?
                              <div id="objValN">{obj.obj[k]}</div>:
                              typeof obj.obj[k]==='boolean'?
                                <div id="objValB">{obj.obj[k].toString()}</div>:
                                <div>Null</div>
                          }
                        </div>
                    }
                    
              </div>
          })
        }
        </div>
        
      </Collapse>
    </div>
    
  );


}