import React from "react";
import { Row, Col } from "react-bootstrap";
function Section(props) {
  return (
    <Row id="sectRow">
        <Col md={props.first?{span:6,order:1}:{span:6,order:2}} sm={12}><img style={{width:"100%"}} src={props.img}/></Col>
        <Col md={props.first?{span:6,order:2}:{span:6,order:1}} sm={12}>{props.children}</Col>
    </Row>
  );
}
export default Section;
