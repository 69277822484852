import React from "react";
import { Navbar, Nav, Link} from "react-bootstrap";

class Navigation extends React.Component {
  render() {
    return (
      <Navbar variant="light" expand="md" fixed="top">
        <div className="container">
          <Navbar.Brand />
          <Navbar.Toggle  className="text-right"/>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto align-items-end nav-fill w-100">
              <Nav.Link href="#home">JSON Help.io</Nav.Link>
              <Nav.Link href="#whatisJSON">What is JSON</Nav.Link>
              <Nav.Link href="#howTo">How Editor Works</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    );
  }
}
export default Navigation;
