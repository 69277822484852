import logo from './logo.svg';
import './App.css';
import { Route, Switch, Link, BrowserRouter as Router } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from "./Components/Navigation";
import Editor from "./Pages/Tool";
import What from "./Pages/What";
import How from "./Pages/How";
import AdSense from 'react-adsense';
import AdsComponent from './Components/AdsComponent';

function App() {
  return (
    <div className="App">
      <Navigation/>
      <>
            <h1>Place To show Google AdSense</h1>
           <AdsComponent dataAdSlot='X7XXXXXX5X' />
        </>
      <Editor id="section"/>
      <What id="section"/>
      <How id="section"/>
    </div>
  );
}

export default App;
