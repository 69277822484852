import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col} from "react-bootstrap";

import jsonImg from "../Content/json.JPG";
import jsonImg2 from "../Content/json2.jpg";
import Section from "../Components/Section";
class What extends React.Component {
  
  render() {
    
    return (
      <div id="whatisJSON">
            <Container id="foot">
                <h1>What is JSON?</h1>
                <br/>
                <br/>
                <Section
                img={jsonImg}
                first={true}>
                    <br/>
                    <h3>The Most Important Tool of the Internet!</h3>
                    <p>Gaining popularity in the 2000s, JSON is a popular library for transmitting data over the internet. Unlike its legacy competitor (YAML), JSON became very popular in transmitting data between websites using REST frameworks.</p>
                    <br className="d-none d-lg-flex" />
                    <p>However because of its versatility, JSON has become a viable asset when storing data. Although you could use it to store local app data, JSON gave birth to popular NoSQL databases such as MongoDB.</p>
                  
                </Section>
                <Section
                img={jsonImg2}
                ><br/>
                    
                    <br className="d-none d-lg-flex" />
                    <h3>What Makes JSON so Versatile?</h3>
                    <br className="d-none d-lg-flex" />
                    <p>The way that JSON works is that it is based on a "key" and "Value" pair (where "key" is the index meaning you get the result "value" when you provide "key" like a dictionary). </p>
                    <br className="d-none d-lg-flex" />
                    <p>JSON "Values" can be standardized as a <span id="objValS">String (text)</span>, <span id="objValN">Numbers</span>, and <span id="objValB">Booleans (True/False)</span>. But JSON goes a step further by allowing you to also set "Values" as another JSON Object or even an Array (a list of JSON Objects or Strings, etc).</p>
                  
                  </Section>
            </Container>
      </div>
    );
  }
}
export default What;
